#pricing {
    height: 1150px;
    padding-top: 100px;
    padding-bottom: 100px;
    background: var(--pricing);
}

#pricingTitle {
    margin-bottom: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: var(--pricingTitle);
    font-weight: 600;
    font-size: 24px;
    color: var(--pricingTitleText);
}

#pricingSubtitle {
    margin-bottom: 40px;
    padding-bottom: 30px;
    font-weight: 600;
    font-size: 18px;
    color: var(--pricingSubtitleText);
}

.pricingBox {
    width: 80%;
    margin-bottom: 50px;
    margin-left: 10%;
    padding: 30px;
    border-radius: 10px;
    background: var(--pricingBox);
    text-align: left;
    font-weight: 500;
    font-size: 18px;
}

.pricingBoxTitle {
    margin-bottom: 10px;
    text-decoration: underline;
    font-weight: 600;
    font-size: 24px;
    color: var(--pricingBoxTitle);
}

#pricingBoxBottom {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 5%;
    padding: 30px;
    border-radius: 10px;
    background: var(--pricingBox);
    text-align: center;
    font-weight: 500;
    font-size: 18px;
}

#pricingSmartphone {
    height: 1950px;
    padding-top: 100px;
    padding-bottom: 100px;
    background: var(--pricing);
}