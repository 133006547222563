#header {
    height: 600px;
    padding-top: 150px;
    margin-bottom: 20px;
}

#headerImage {
    display: flex;
    height: 300px;
    margin-top: 100px;
    margin-left: 55%;
}

#headerTitle {
    position: absolute;
    top: 200px;
    left: 25%;
    font-weight: 600;
    font-size: 40px;
    color: var(--headerText);
}

#headerBand {
    z-index: 0;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    height: 100px;
    width: 100%;
    background: var(--headerBand);
}

#headerBandText {
    height: 100px;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-left: 20%;
    font-weight: 600;
    font-size: 26px;
    color: var(--headerBandText);
}

#headerBandIcon {
    height: 140px;
    margin-top: -20px;
    margin-left: 5%;
}

#headerSmartphoneTitle {
    font-weight: 600;
    font-size: 30px;
    color: var(--headerText);
}

#headerSmartphoneImage {
    display: flex;
    height: 200px;
    margin-top: 20px;
    margin-left: 20%;
}

#headerSmartphoneBand {
    z-index: 0;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    height: 80px;
    width: 100%;
    background: var(--headerBand);
}

#headerSmartphoneBandIcon {
    height: 100px;
    margin-top: -10px;
    margin-left: 5%;
}

#headerSmartphoneBandText {
    height: 80px;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-left: 5%;
    font-weight: 600;
    font-size: 14px;
    color: var(--headerBandText);
}