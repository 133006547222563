#nav {
    z-index: 1;
    position: fixed;
    top: 0;
    display: flex;
    height: 60px;
    width: 100%;
    background: var(--nav);
    text-transform: uppercase;
}

#navLogo {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50px;
}

#navLinks {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    padding-left: 3%;
}

.navLink {
    cursor: pointer;
    transition: all 0.6s;
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    color: var(--navText1);
}

.navLink:hover {
    color: var(--navText2);
}

#navSmartphoneBurger {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10%;
    padding-right: 30%;
    background: var(--nav);
}

.navSmartphoneBurgerLink {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    white-space: nowrap;
    text-align: left;
    color: var(--navText1);
}

.navSmartphoneBurglar {
    transform: scale(0.7);
    position: relative;
    display: block;
    width: 68px;
    height: 68px;
    margin-left: 5%;
    -webkit-touch-callout: none;
    user-select: none;
}

.path-burger {
    position: absolute;
    top: 0;
    left: 0;
    height: 68px;
    width: 68px;
    mask: url(#mask);
}

.animate-path {
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
}

.path-rotation {
    height: 34px;
    width: 34px;
    margin: 34px 34px 0 0;
    transform: rotate(0deg);
    transform-origin: 100% 0;
}

.path-rotation:before {
    content: '';
    display: block;
    width: 30px;
    height: 34px;
    margin: 0 4px 0 0;
    background: #000000;
}

@keyframes rotate-out {
    0% {
        transform: rotate(0deg);
    }

    40% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate-in {
    0% {
        transform: rotate(360deg);
    }

    40% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.navSmartphoneBurglar.is-open .path {
    animation: dash-in 0.6s linear normal;
    animation-fill-mode: forwards;
}

.navSmartphoneBurglar.is-open .animate-path {
    animation: rotate-in 0.6s linear normal;
    animation-fill-mode: forwards;
}

.navSmartphoneBurglar.is-closed .path {
    animation: dash-out 0.6s linear normal;
    animation-fill-mode: forwards;
}

.navSmartphoneBurglar.is-closed .animate-path {
    animation: rotate-out 0.6s linear normal;
    animation-fill-mode: forwards;
}

.path {
    stroke-dasharray: 240;
    stroke-dashoffset: 240;
    stroke-linejoin: round;
}

@keyframes dash-in {
    0% {
        stroke-dashoffset: 240;
    }

    40% {
        stroke-dashoffset: 240;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash-out {
    0% {
        stroke-dashoffset: 0;
    }

    40% {
        stroke-dashoffset: 240;
    }

    100% {
        stroke-dashoffset: 240;
    }
}

.navSmartphoneBurgerIcon {
    position: absolute;
    padding: 20px 16px;
    height: 68px;
    width: 68px;
}

.navSmartphoneBurgerIconContainer {
    position: relative;
    height: 28px;
    width: 36px;
}

.navSmartphoneBurgerBunTop,
.navSmartphoneBurgerBunBot,
.navSmartphoneBurgerFilling {
    position: absolute;
    display: block;
    height: 4px;
    width: 36px;
    border-radius: 2px;
    background: #000000;
}

.navSmartphoneBurgerBunTop {
    top: 0;
    transform-origin: 34px 2px;
}

.navSmartphoneBurgerBunBot {
    bottom: 0;
    transform-origin: 34px 2px;
}

.navSmartphoneBurgerFilling {
    top: 12px;
}

.burger-ring {
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
}

.svg-ring {
    width: 68px;
    height: 68px;
}

.navSmartphoneBurglar.is-open .navSmartphoneBurgerBunTop {
    animation: bun-top-out 0.6s linear normal;
    animation-fill-mode: forwards;
}

.navSmartphoneBurglar.is-open .navSmartphoneBurgerBunBot {
    animation: bun-bot-out 0.6s linear normal;
    animation-fill-mode: forwards;
}

.navSmartphoneBurglar.is-closed .navSmartphoneBurgerBunTop {
    animation: bun-top-in 0.6s linear normal;
    animation-fill-mode: forwards;
}

.navSmartphoneBurglar.is-closed .navSmartphoneBurgerBunBot {
    animation: bun-bot-in 0.6s linear normal;
    animation-fill-mode: forwards;
}

@keyframes bun-top-out {
    0% {
        left: 0;
        top: 0;
        transform: rotate(0deg);
    }

    20% {
        left: 0;
        top: 0;
        transform: rotate(15deg);
    }

    80% {
        left: -5px;
        top: 0;
        transform: rotate(-60deg);
    }

    100% {
        left: -5px;
        top: 1px;
        transform: rotate(-45deg);
    }
}

@keyframes bun-bot-out {
    0% {
        left: 0;
        transform: rotate(0deg);
    }

    20% {
        left: 0;
        transform: rotate(-15deg);
    }

    80% {
        left: -5px;
        transform: rotate(60deg);
    }

    100% {
        left: -5px;
        transform: rotate(45deg);
    }
}

@keyframes bun-top-in {
    0% {
        left: -5px;
        bot: 0;
        transform: rotate(-45deg);
    }

    20% {
        left: -5px;
        bot: 0;
        transform: rotate(-60deg);
    }

    80% {
        left: 0;
        bot: 0;
        transform: rotate(15deg);
    }

    100% {
        left: 0;
        bot: 1px;
        transform: rotate(0deg);
    }
}

@keyframes bun-bot-in {
    0% {
        left: -5px;
        transform: rotate(45deg);
    }

    20% {
        left: -5px;
        bot: 0;
        transform: rotate(60deg);
    }

    80% {
        left: 0;
        bot: 0;
        transform: rotate(-15deg);
    }

    100% {
        left: 0;
        transform: rotate(0deg);
    }
}

.navSmartphoneBurglar.is-open .navSmartphoneBurgerFilling {
    animation: burger-fill-out 0.6s linear normal;
    animation-fill-mode: forwards;
}

.navSmartphoneBurglar.is-closed .navSmartphoneBurgerFilling {
    animation: burger-fill-in 0.6s linear normal;
    animation-fill-mode: forwards;
}

@keyframes burger-fill-in {
    0% {
        width: 0;
        left: 36px;
    }

    40% {
        width: 0;
        left: 40px;
    }

    80% {
        width: 36px;
        left: -6px;
    }

    100% {
        width: 36px;
        left: 0px;
    }
}

@keyframes burger-fill-out {
    0% {
        width: 36px;
        left: 0px;
    }

    20% {
        width: 42px;
        left: -6px;
    }

    40% {
        width: 0;
        left: 40px;
    }

    100% {
        width: 0;
        left: 36px;
    }
}