#contact {
    height: 600px;
    margin-bottom: 100px;
    padding-top: 0px;
}

#contactLeft {
    padding-left: 20%;
    text-align: left;
}

#contactLeftTitle {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 30px;
    color: var(--contactTitle);
}

.contactLeftText {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 22px;
}

.contactLeftTextIcon {
    margin-right: 10px;
}

#contactLeftImage {
    width: 100%;
    border-radius: 10px;
}

#contactForm {
    margin-left: 5%;
    margin-right: 5%;
    padding: 50px 5% 50px 5%;
    border-radius: 10px;
    background: var(--contactForm);
}

#contactFormButtonContainer {
    display: flex;
    justify-content: center;
}

#contactFormButton {
    transition: all 0.3s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 20px;
    background: var(--contactFormButton);
    font-weight: 500;
    font-size: 20px;
    color: var(--contactFormButtonText);
}

#contactFormButton:hover {
    padding-left: 60px;
    padding-right: 60px;
    background: var(--contactFormButtonActive);
    color: var(--contactFormButtonActiveText);
}

#contactSmartphoneLeft {
    padding-left: 10%;
    text-align: left;
}

#contactSmartphoneLeftTitle {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 20px;
    color: var(--contactTitle);
}

.contactSmartphoneLeftText {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 22px;
}

#contactSmartphoneLeftImage {
    width: 90%;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 10px;
}

#contactSmartphoneForm {
    margin-top: 30px;
    margin-left: 5%;
    margin-right: 5%;
    padding: 50px 5% 50px 5%;
    border-radius: 10px;
    background: var(--contactForm);
}

#contactSmartphone {
    margin-bottom: 100px;
    padding-top: 0px;
}