#presentation {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 500px;
    width: 100%;
    padding-top: 200px;
    padding-bottom: 100px;
    background: var(--presentation);
}

#presentationImage {
    display: flex;
    justify-content: center;
    width: 130%;
    margin-left: 30%;
    border-radius: 10px;
}

#presentationText {
    margin-left: 15%;
    margin-right: 10%;
    text-align: left;
}

#presentationTextTitle {
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 24px;
    color: var(--presentationText);
}

.presentationTextParagraph {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    color: var(--presentationText);
}

.presentationTextRed {
    color: var(--presentationTextRed);
}

#presentationSmartphone {
    width: 100%;
    margin-top: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    background: var(--presentation);
}

#presentationSmartphoneImage {
    width: 80%;
    margin-bottom: 50px;
    border-radius: 10px;
}

#presentationSmartphoneText {
    margin-left: 3%;
    margin-right: 3%;
    text-align: left;
}

.presentationSmartphoneTextParagraph {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    color: var(--presentationText);
}