.switchSelectorContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;
}

.largeSwitchSelector {
    height: 32px;
    width: 90%;
}
