:root {

    --nav: #f8f4f4;
    --navText1: #808080;
    --navText2: #e93b3b;

    --headerText: #5e5e5e;
    --headerBand: #7f7f7f;
    --headerBandText: #f5f5f3;

    --presentation: #7f7f7f;
    --presentationText: #f5f5f3;
    --presentationTextRed: #e93b3b;

    --solutionText: #5e5e5e;
    --solutionTextRed: #e93b3b;
    --solutionBand: #7f7f7f;
    --solutionBandText: #f5f5f3;

    --solutionRight: #f8f4f4;
    --solutionRightText: #7f7f7f;

    --pricing: #7f7f7f;
    --pricingTitle: #f8f4f4;
    --pricingTitleText: #7f7f7f;
    --pricingSubtitleText: #f8f4f4;
    --pricingBox: #f8f4f4;
    --pricingBoxTitle: #e93b3b;

    --contactTitle: #e93b3b;
    --contactForm: #7f7f7f;
    --contactFormButton: #f8f4f4;
    --contactFormButtonText: #525252;
    --contactFormButtonActive: #525252;
    --contactFormButtonActiveText: #f8f4f4;

    --inputText: #f8f4f4;
    --inputTextText: #215480;
    --inputTextSuccess: #ffffff;
    --inputTextSuccessText: #58815a;
    --inputTextError: #ffffff;
    --inputTextErrorText: #bb4040;

}