  .input-group {
      margin-bottom: 30px !important;
  }

  .form-control:focus {
      box-shadow: 0 0 3px !important;
  }

  .inputTextIcon {
      z-index: 1 !important;
      height: 38px !important;
      width: 50px !important;
      margin: 1px -50px 0 0 !important;
      padding-left: 15px !important;
      border-radius: 20px 0 0 20px !important;
      background: var(--inputText) !important;
      color: var(--inputTextText) !important;
  }

  .inputTextIconSuccess {
      z-index: 1 !important;
      height: 40px !important;
      width: 50px !important;
      margin-right: -50px;
      padding-left: 15px !important;
      border-radius: 20px 0 0 20px !important;
      border: 2px solid var(--inputTextSuccessText) !important;
      background: var(--inputTextError) !important;
      color: var(--inputTextSuccessText) !important;
  }

  .inputTextIconError {
      z-index: 1 !important;
      height: 40px !important;
      width: 50px !important;
      margin-right: -50px;
      padding-left: 15px !important;
      border-radius: 20px 0 0 20px !important;
      border: 2px solid var(--inputTextErrorText) !important;
      background: var(--inputTextError) !important;
      color: var(--inputTextErrorText) !important;
  }

  .inputText {
      z-index: 0 !important;
      height: 40px !important;
      padding-left: 60px !important;
      border-radius: 20px !important;
      background: var(--inputText) !important;
      color: var(--inputTextText) !important;
      font-size: 18px !important;
      font-weight: 600 !important;
  }

  .inputTextSuccess {
      z-index: 0 !important;
      height: 40px !important;
      padding-left: 60px !important;
      border-radius: 20px !important;
      border: 2px solid var(--inputTextSuccessText) !important;
      background: var(--inputTextSuccess) !important;
      color: var(--inputTextSuccessText) !important;
      font-size: 18px !important;
      font-weight: 600 !important;
  }

  .inputTextError {
      z-index: 0 !important;
      height: 40px !important;
      padding-left: 60px !important;
      border-radius: 20px !important;
      border: 2px solid var(--inputTextErrorText) !important;
      background: var(--inputTextError) !important;
      color: var(--inputTextErrorText) !important;
      font-size: 18px !important;
      font-weight: 600 !important;
  }