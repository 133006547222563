#solution {
    height: 1000px;
    width: 100%;
    padding-top: 150px;
}

#solutionTitle {
    margin-top: 70px;
    font-weight: 600;
    font-size: 24px;
    color: var(--solutionText);
}

#solutionText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
}

#solutionTextLeft {
    margin-left: 5%;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
}

#solutionTextLeftTitle {
    font-weight: 700;
    font-size: 24px;
    color: var(--solutionTextRed);
}

#solutionTextRight {
    width: 70%;
    margin-left: 10%;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 20px;
    background: var(--solutionRight);
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    color: var(--solutionRightText);
}

.solutionTextRightParagraph {
    margin-bottom: 50px;
}

#solutionImage {
    display: flex;
    height: 400px;
    margin-top: -50px;
    margin-left: 55%;
}

#solutionBand {
    z-index: 0;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    height: 100px;
    width: 100%;
    background: var(--solutionBand);
}

#solutionBandText {
    height: 100px;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-left: 20%;
    font-weight: 600;
    font-size: 30px;
    color: var(--solutionBandText);
}

#solutionBandIcon {
    height: 140px;
    margin-top: -20px;
    margin-left: 5%;
}

#solution {
    width: 100%;
    margin-top: 0px;
    padding-top: 0px;
}

#solutionSmartphoneTitle {
    margin-bottom: 30px;
    padding-left: 3%;
    padding-right: 3%;
    font-weight: 600;
    font-size: 18px;
    color: var(--solutionText);
}

#solutionSmartphoneTextLeftTitle {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 20px;
    color: var(--solutionTextRed);
}

#solutionSmartphoneTextLeft {
    margin-bottom: 30px;
    margin-left: 5%;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
}

#solutionSmartphoneTextRight {
    width: 90%;
    margin-left: 5%;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 20px;
    background: var(--solutionRight);
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    color: var(--solutionRightText);
}

.solutionSmartphoneTextRightParagraph {
    margin-bottom: 20px;
}

#solutionSmartphoneImage {
    display: flex;
    height: 200px;
    margin-top: 20px;
    margin-left: 20%;
}

#solutionSmartphoneBand {
    z-index: 0;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    height: 80px;
    width: 100%;
    background: var(--solutionBand);
}

#solutionSmartphoneBandIcon {
    height: 100px;
    margin-top: -10px;
    margin-left: 5%;
}

#solutionSmartphoneBandText {
    height: 80px;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-left: 5%;
    font-weight: 600;
    font-size: 14px;
    color: var(--solutionBandText);
}