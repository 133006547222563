.input-group {
    margin-bottom: 20px !important;
}

.form-control:focus {
    box-shadow: 0 0 3px !important;
}

.inputTextareaIcon {
    z-index: 1 !important;
    width: 50px !important;
    margin-right: -50px;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    background: var(--inputText) !important;
    color: var(--inputTextText) !important;
}

.inputTextareaIconSuccess {
    z-index: 1 !important;
    width: 50px !important;
    margin-right: -50px;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    border: 2px solid var(--inputTextSuccessText) !important;
    background: var(--inputTextError) !important;
    color: var(--inputTextSuccessText) !important;
}

.inputTextareaIconError {
    z-index: 1 !important;
    width: 50px !important;
    margin-right: -50px;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    border: 2px solid var(--inputTextErrorText) !important;
    background: var(--inputTextError) !important;
    color: var(--inputTextErrorText) !important;
}

.inputTextarea {
    z-index: 0 !important;
    height: 120px !important;
    padding-left: 60px !important;
    border-radius: 20px !important;
    background: var(--inputText) !important;
    color: var(--inputTextText) !important;
    font-size: 14px !important;
    font-weight: 550 !important;
}

.inputTextareaSuccess {
    z-index: 0 !important;
    height: 120px !important;
    padding-left: 60px !important;
    border-radius: 20px !important;
    border: 2px solid var(--inputTextSuccessText) !important;
    background: var(--inputTextSuccess) !important;
    color: var(--inputTextSuccessText) !important;
    font-size: 14px !important;
    font-weight: 550 !important;
}

.inputTextareaError {
    z-index: 0 !important;
    height: 120px !important;
    padding-left: 60px !important;
    border-radius: 20px !important;
    border: 2px solid var(--inputTextErrorText) !important;
    background: var(--inputTextError) !important;
    color: var(--inputTextErrorText) !important;
    font-size: 14px !important;
    font-weight: 550 !important;
}