#footer {
    display: flex;
    height: 60px;
    width: 100%;
    background: var(--footer);
    text-transform: uppercase;
    justify-content: space-around;
}

#footerLogo {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50px;
}

#footerText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    white-space: nowrap;
    font-size: 14px;
    color: var(--footerText1);
}

#footerLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    white-space: nowrap;
    font-size: 14px;
}

#footerLink a {
    color: var(--footerText1);
}

#footerSmartphone {
    height: 60px;
    width: 100%;
    background: var(--footer);
    text-transform: uppercase;
}

#footerSmartphoneText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    margin-top: 30px;
    margin-bottom: 50px;
    font-size: 14px;
    color: var(--footerText1);
}

#footerSmartphoneLink {
    padding-bottom: 20px;
}

#footerSmartphoneLink a {
    color: var(--footerText1);
}
