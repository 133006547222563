#cgu {
    padding-top: 100px;
    margin-bottom: 20px;
    padding-left: 10%;
    padding-right: 10%;
}

#cguTitle {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 25px;
}

#cguTitle::after {
    content: "";
    width: 10%;
    height: 1px;
    background-color: #ddd;
    display: block;
    margin: 1rem auto;
}

.cguTextParagraph {
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 10px;
    color: #333;
}

.cguTitleParagraph {
    font-size: 26px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 15px;
    margin-top: 5px;
}

#cguSmartphone {
    padding: 80px 5% 30px 5%;
}

#cguSmartphone #cguTitle {
    font-size: 26px;
}

#cguSmartphone #cguTitle::after {
    width: 35%;
}

#cguSmartphone .cguTextParagraph {
    text-align: justify;
}
